jQuery(document).ready(function($)
{
	// AJAX submission for add to basket form
	$('.add_to_basket_form.ajax form').on('submit', function(event) {
		event.preventDefault();

		var form = $(this);

		$.ajax({
			method: 'POST',
			data: form.serialize(),
			contentType: 'application/x-www-form-urlencoded',
			cache: false,
			headers: {
				'Accept': 'application/json',
			},
			beforeSend: function(){
				form.find('button').prop('disabled', true);
				form.prop('disabled', true);
			},
			complete: function(){
				form.find('button').prop('disabled', false);
				form.prop('disabled', false);
			},
			success: function(data){
				var cart = data.cart;
				var basket_text = '£' + cart.totalPrice + ' (' + cart.totalQty + ' item' + (cart.totalQty == 1 ? '' : 's') + ')';
				$('.basket_link').text(basket_text);

				var modal = $('.modal_wrapper.added_to_basket_modal');
				var product_title = form.closest('.add_to_basket_form').attr('data-title');
				var product_image = form.closest('.add_to_basket_form').attr('data-image');
				modal.find('.modal_title').text(product_title + ' has been added to your basket');
				modal.find('.image').empty().append('<img src="'+product_image+'">');
				modal.addClass('visible');
			},
			error: function (xhr, ajaxOptions, thrownError) {
				alert("There was an error. Please try again.");
				// console.log("ERROR: " + xhr.status + " " + thrownError);
			}
		});

		return false;
	});

	// Plus / minus on products and course bookings
	// Don't allow adding more items to cart than there are available in total
	$('.qty_buttons .button').on('click', function(event)
	{
		event.stopPropagation();

		var input = $(this).closest('.qty_buttons').find('input.qty_input');
		var increment = $(this).attr('data-increment');
		var qty = Number(input.val());
		var stock_remaining = Number($(this).closest('.add_to_basket_form, .basket_item').attr('data-stock-remaining'));

		var total_booked = 0;
		$(this).closest('.add_to_basket_form, .basket_item').find('input.qty_input').each(function(index)
		{
			total_booked += Number($(this).val());
		});

		if (increment == '+')
		{
			if (stock_remaining > total_booked)
			{
				total_booked++;
				qty++;
			}
		}
		else if (increment == '-')
		{
			if (qty !== 0)
			{
				total_booked--;
				qty--;
			}
		}
		input.val(qty);

		if (total_booked > 0)
		{
			input.closest('form').find('button, input[type=submit]').prop('disabled', false);
		}
		else
		{
			input.closest('form').find('button, input[type=submit]').prop('disabled', true);
		}

		return false;
	});

	// Remove item from basket
	$('.remove_item_from_basket').show();
	$('.remove_item_from_basket_btn').on('click', function(event)
	{
		event.stopPropagation();

		$(this).siblings('.remove_item_input').prop('disabled', false);
		$(this).closest('form').submit();

		return false;
	});

});
